* {
  box-sizing: border-box;
}
[role="button"] {
  cursor: pointer;
}
html {
  height: 100%;
  font-size: 62.5%;
  direction: ltr;
}
html[lang='ar'] {
  direction: rtl;
}
body {
  height: 100%;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: initial;
  color: #fff;
  background: #293951;
  overflow: hidden;
}
.layout {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1366px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.app {
  flex-grow: 1;
}
.app iframe {
  width: 100%;
  border: none;
}
header {
  height: 6rem;
  line-height: 6rem;
  text-align: right;
  background: #005999;
}
.logo {
  height: 6rem;
  line-height: 6rem;
  font-size: 0;
}
.logo__img {
  display: inline-block;
  vertical-align: middle;
  height: 2.4rem;
}
.header__login {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  line-height: 1.3;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  border-radius: 2px;
  background: transparent;
  transition: all 0.3s linear;
}
.header__login:hover {
  background: #0068b3;
}
.header__register {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  margin-right: 30px;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  line-height: 1.3;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  border-radius: 2px;
  background-color: #7fba2f;
  transition: all 0.3s linear;
}
.header__register:hover {
  background: #84cb20;
}
.lang {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 14rem;
  text-align: left;
}
.lang.active {
  background: #ffffff;
}
.lang__overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}
.lang__overlay.active {
  display: block;
}
.lang__selected {
  position: relative;
  z-index: 1;
  border-radius: 2px;
  background: transparent;
  transition: all 0.3s linear;
}
.lang__selected:hover {
  background: #1883cf;
}
.lang__list {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 3.6rem;
  font-size: 0;
  background-color: #1883cf;
  border-color: #ffffff;
  border-radius: 2px;
}
html[lang="ar"] .lang__list {
  text-align: right;
  left: auto;
  left: initial;
  right: 0;
}
.lang__list.active {
  display: block;
  box-shadow: 0 1px 4px rgba(151, 151, 151, 0.5);
}
.lang__item {
  display: block;
  padding: 1rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #ffffff;
  white-space: nowrap;
}
.lang__item.active {
  display: none;
}
.lang__item::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 11px;
  margin-right: 0.8rem;
  background: url('http://prize.roboforex.com/bundles/appfront/images/flags-country.png') no-repeat;
}
html[lang='ar'] .lang__item::before {
  margin-right: 0;
  margin-left: 0.8rem;
}
.lang__item[href*='de']::before {
  background-position: -80px -33px;
}
.lang__item[href*='uk']::before {
  background-position: -96px -154px;
}
.lang__item[href*='pl']::before {
  background-position: -32px -121px;
}
.lang__item[href*='es']::before {
  background-position: -16px -44px;
}
.lang__item[href*='zt']::before {
  background-position: -64px -154px;
}
.lang__item[href*='ar']::before {
  background-position: -32px 0;
}
.lang__item[href*='it']::before {
  background-position: -208px -66px;
}
.lang__item[href*='ru']::before {
  background-position: -224px -121px;
}
.lang__item[href*='pt']::before {
  background-position: -112px -121px;
}
.lang__item[href*='ms']::before {
  background-position: -208px -99px;
}
.lang__item[href*='id']::before {
  background-position: -64px -66px;
}
.lang__item[href*='zh']::before {
  background-position: -208px -22px;
}
.lang__item[href*='en']::before {
  background-position: -144px -154px;
}
.lang__item[href*='th']::before {
  background-position: -160px -143px;
}
.lang__item[href*='lt']::before {
  background-position: -80px -88px;
}
footer {
  height: 6rem;
  line-height: 6rem;
  text-align: right;
  color: #9fa2a8;
}
.disclaimer {
  display: inline-block;
  vertical-align: middle;
  padding-left: 3rem;
  font-size: 1.3rem;
  line-height: 1.3;
}
